import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const PHONE_CONSULTATIONS_LOCALES = {
	WOLTAIR_CZ: 'telefonni-konzultace',
	WOLTAIR_PL: 'konsultacja-telefoniczna',
	WOLTAIR_DE: null,
	WOLTAIR_IT: null
}

export const PHONE_CONSULTATIONS =
	PHONE_CONSULTATIONS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return PHONE_CONSULTATIONS === param
}) satisfies ParamMatcher
